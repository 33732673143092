import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode>
		        <Toaster
          // position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{ marginTop: 50 }}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#03c04a",
              color: "#fff",
							fontSize: "1.5rem",
            },

            // Default options for specific types
						success: {
							style: {
								background: "#03c04a",
							},
						},
						error: {
							style: {
								background: 'red',
							},
						},
          }}
        />
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
