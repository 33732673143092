import { createContext, useContext, useEffect, useState } from "react";

export const DbContext = createContext ({
	children: null,
	IDBDatabase: null,
});

export const useDbContext = () => useContext(DbContext);

export const DbContextProvider = ({ children }) => {
	const [db, setDb] = useState(null);

	useEffect(() => {
		const request = window.indexedDB.open("seedsDB", 1);

		request.onupgradeneeded = function (event) {
			const upgradeEvent = event;
			const db = upgradeEvent.target.result;
			const objectStore = db.createObjectStore("seedData", {
				keyPath: "id",
				autoIncrement: true,
			});
			objectStore.createIndex("dateReceived", "dateReceived", { unique: false });
			objectStore.createIndex("commonName", "commonName", { unique: false });
			objectStore.createIndex("count", "count", { unique: false });
		};

		request.onsuccess = function (event) {
			const successEvent = event;
			setDb(successEvent.target.result);
		};

		request.onerror = function (event) {
			console.log("Database error");
		};
	}, []);

	return <DbContext.Provider value={{ children, IDBDatabase: db }}>{children}</DbContext.Provider>;
}

